import { Group, AmbientLight, DirectionalLight } from 'three'
import { Component, Vue } from 'vue-property-decorator'

@Component
export default class Lights extends Vue {
  instance!: Group

  mounted() {
    this.instance = new Group()
    this.gl.scene.webgl.add(this.instance)

    const ambientLight = new AmbientLight(0x222222, 2)
    this.instance.add(ambientLight)

    const directionalLightLeft = new DirectionalLight(0xffffff, 0.9)
    directionalLightLeft.position.set(-3, 2, 5)
    this.instance.add(directionalLightLeft)

    const directionalLightRight = new DirectionalLight(0xffffff, 0.3)
    directionalLightRight.position.set(3, 0, 0)
    this.instance.add(directionalLightRight)
  }

  beforeDestroy() {
    this.gl.scene.webgl.remove(this.instance)
  }

  render() {
    return null
  }
}
