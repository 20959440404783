import { Object3D } from 'three'
import TextChunk from './chunk'

export default class TextInstance extends Object3D {
  top: TextChunk
  bot: TextChunk

  constructor() {
    super()

    this.top = new TextChunk()
    this.bot = new TextChunk()

    this.add(this.top)
    this.add(this.bot)
  }

  progress(progress: number) {
    this.top.material.uniforms.uProgress.value = progress
    this.bot.material.uniforms.uProgress.value = progress
  }

  update(text: string) {
    this.top.update(text, 1)
    this.bot.update(text, -1)
  }

  dispose() {
    this.remove(this.top)
    this.remove(this.bot)
    this.top.dispose()
    this.bot.dispose()
  }
}
