import { gsap } from 'gsap'
import { isEqual } from 'lodash'
import { getAsset } from '@/services'
import { Color, Object3D } from 'three'
import { Component, Vue, Prop, Watch } from 'vue-property-decorator'
import Pack from './Instance'

@Component
export default class ProductPack extends Vue {
  @Prop() theme!: any
  @Prop() scale!: number
  @Prop() uniforms!: any
  @Prop() progress!: number
  @Prop() rotation!: number
  @Prop() position!: number
  @Prop() direction!: number
  @Prop() prevProgress!: number
  @Prop() nextProgress!: number

  prevInstance!: Pack
  nextInstance!: Pack

  container!: Object3D

  prevTheme!: any

  get forwards() {
    return this.direction > 0
  }

  @Watch('prevProgress', { immediate: false })
  onPrevProgressUpdate(progress: number) {
    this.prevInstance && this.prevInstance.setProgres(progress)
  }

  @Watch('nextProgress', { immediate: false })
  onNextProgressUpdate(progress: number) {
    this.nextInstance && this.nextInstance.setProgres(progress)
    if ((this.forwards && progress === 1) || (!this.forwards && progress === 0)) {
      this.prevInstance &&
        (this.container.remove(this.prevInstance), this.prevInstance.dispose(true))
    }
  }

  @Watch('position', { immediate: true })
  onPositionUpdate(position: number) {
    this.prevInstance && this.prevInstance.setPosition(position)
    this.nextInstance && this.nextInstance.setPosition(position)
  }

  @Watch('rotation', { immediate: true })
  onRotationUpdate(rotation: number) {
    this.prevInstance && this.prevInstance.setRotation(rotation)
    this.nextInstance && this.nextInstance.setRotation(rotation)
  }

  @Watch('scale', { immediate: true })
  onScaleUpdate(scale: number) {
    this.prevInstance && this.prevInstance.setScale(scale)
    this.nextInstance && this.nextInstance.setScale(scale)
  }

  @Watch('theme', { immediate: true })
  async onThemeUpdate(nextTheme: any, prevTheme: any) {
    if (isEqual(nextTheme, prevTheme)) return

    await this.$nextTick()

    const uPrevMap = this.forwards ? 'uPrevMap' : 'uNextMap'
    const uNextMap = this.forwards ? 'uNextMap' : 'uPrevMap'

    const uPrevOpacity = this.forwards ? 'uPrevOpacity' : 'uNextOpacity'
    const uNextOpacity = this.forwards ? 'uNextOpacity' : 'uPrevOpacity'

    const uPrevRoughness = this.forwards ? 'uPrevRoughness' : 'uNextRoughness'
    const uNextRoughness = this.forwards ? 'uNextRoughness' : 'uPrevRoughness'

    const uProgress = 'uProgress'
    const uNextColor = 'uNextColor'
    const uNextColorDark = 'uNextColorDark'
    const uNextColorLight = 'uNextColorLight'

    this.prevInstance && (this.container.remove(this.prevInstance), this.prevInstance.dispose())
    this.nextInstance && (this.container.remove(this.nextInstance), this.nextInstance.dispose())

    if (this.prevTheme) {
      const color = new Color(this.prevTheme.color)
      const colorDark = color.clone().offsetHSL(0, 0, -0.1)
      const colorLight = color.clone().offsetHSL(0, 0, 0.1)

      this.prevInstance = new Pack({ theme: this.prevTheme, uniforms: this.uniforms })
      this.prevInstance.material.uniforms[uPrevMap].value = getAsset(this.prevTheme.texture)
      this.prevInstance.material.uniforms[uPrevRoughness].value = getAsset(this.prevTheme.roughness)
      this.prevInstance.material.uniforms[uNextColorLight].value.set(colorLight)
      this.prevInstance.material.uniforms[uNextColorDark].value.set(colorDark)
      this.prevInstance.material.uniforms[uNextColor].value.set(color)
      this.prevInstance.material.uniforms[uPrevOpacity].value = 1
      this.prevInstance.material.uniforms[uNextOpacity].value = 0
      this.prevInstance.material.uniforms[uProgress].value = this.forwards ? 0 : 1
      this.prevInstance.setPosition(this.position)
      this.prevInstance.setRotation(this.rotation)
      this.prevInstance.setScale(this.scale)
      this.container.add(this.prevInstance)

      gsap.to(this.prevInstance.shadow.uniforms.opacity, {
        value: 0,
        duration: 1,
        ease: 'power2.inOut',
      })
    }

    const color = new Color(nextTheme.color)
    const colorDark = color.clone().offsetHSL(0, 0, -0.1)
    const colorLight = color.clone().offsetHSL(0, 0, 0.1)

    this.nextInstance = new Pack({ theme: nextTheme, uniforms: this.uniforms })
    this.nextInstance.material.uniforms[uNextMap].value = getAsset(nextTheme.texture)
    this.nextInstance.material.uniforms[uNextRoughness].value = getAsset(nextTheme.roughness)
    this.nextInstance.material.uniforms[uNextColorDark].value.set(colorDark)
    this.nextInstance.material.uniforms[uNextColorLight].value.set(colorLight)
    this.nextInstance.material.uniforms[uNextColor].value.set(color)
    this.nextInstance.material.uniforms[uNextOpacity].value = 1
    this.nextInstance.material.uniforms[uPrevOpacity].value = 0
    this.nextInstance.material.uniforms[uProgress].value = this.forwards ? 0 : 1
    this.nextInstance.setPosition(this.position)
    this.nextInstance.setRotation(this.rotation)
    this.nextInstance.setScale(this.scale)
    this.container.add(this.nextInstance)

    gsap.from(this.nextInstance.shadow.uniforms.opacity, {
      value: 0,
      duration: 1,
      ease: 'power2.inOut',
    })

    this.prevTheme = { ...nextTheme }
  }

  mounted() {
    this.container = new Object3D()
    this.container.position.set(0, 0, 0)
    this.container.scale.setScalar(1)
    this.gl.scene.webgl.add(this.container)
  }

  beforeDestroy() {
    this.gl.scene.webgl.remove(this.container)
    this.container.remove(this.prevInstance)
    this.container.remove(this.nextInstance)
    this.prevInstance.dispose()
    this.nextInstance.dispose()
  }

  render() {
    return null
  }
}
