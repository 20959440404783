import { Mesh } from 'three'
import Geometry from './geometry'
import Material from './material'

export default class TextChunk extends Mesh {
  geometry: Geometry
  material: Material

  constructor() {
    super()

    this.geometry = new Geometry()
    this.material = new Material()

    this.frustumCulled = false
    this.renderOrder = 1
  }

  update(text: string, side: number) {
    this.geometry.update(text, side)

    const { width, height, descender } = this.geometry.layout
    const y = -(height - descender - 4) / 2
    const x = -width / 2

    this.rotation.set(Math.PI, 0, 0)
    this.position.set(x, y, 0)
  }

  dispose() {
    this.geometry.dispose()
    this.material.dispose()
  }
}
