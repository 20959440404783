import { Mesh, PlaneGeometry, ShaderMaterial } from 'three'
import vertexShader from 'raw-loader!glslify-loader!./shaders/vertex.glsl'
import fragmentShader from 'raw-loader!glslify-loader!./shaders/fragment.glsl'

interface ShadowOptions {
  width: number
  height: number
  opacity: number
  positionY: number
  positionZ: number
}

export default class Shadow extends Mesh {
  geometry!: PlaneGeometry
  material!: ShaderMaterial

  opacity!: number

  constructor({ width, height, opacity, positionY, positionZ }: ShadowOptions) {
    super(
      new PlaneGeometry(height, width),
      new ShaderMaterial({
        transparent: true,
        vertexShader,
        fragmentShader,
        uniforms: {
          opacity: { value: opacity },
          uPosition: { value: 0 },
          uRotation: { value: 0 },
          uScale: { value: 0 },
        },
      })
    )

    this.rotation.set(-Math.PI / 2, 0, 0)
    this.position.set(-positionZ, -positionY, 0)
  }

  get uniforms() {
    const material = this.material as ShaderMaterial
    return material.uniforms
  }

  setPosition(position: number) {
    this.uniforms.uPosition.value = position
  }

  setRotation(rotation: number) {
    this.uniforms.uRotation.value = rotation
  }

  dispose() {
    const geometry = this.geometry as PlaneGeometry
    const material = this.material as ShaderMaterial
    geometry.dispose()
    material.dispose()
  }
}
