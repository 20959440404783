import { getAsset } from '@/services'
import fragmentShader from 'raw-loader!glslify-loader!./shaders/fragment.glsl'
import vertexShader from 'raw-loader!glslify-loader!./shaders/vertex.glsl'
import { ShaderMaterial } from 'three'

export default class LeafMaterial extends ShaderMaterial {
  constructor() {
    super({
      transparent: true,
      fragmentShader,
      vertexShader,
      uniforms: {
        uTime: { value: 0 },
        uAlpha: { value: 0 },
        uScale: { value: 0 },
        uPosition: { value: 0 },
        uProgress: { value: 0 },

        uPrevMaps: {
          value: [
            getAsset('colorMap1Leaf1'),
            getAsset('colorMap1Leaf2'),
            getAsset('colorMap1Leaf3'),
          ],
        },
        uNextMaps: {
          value: [
            getAsset('colorMap1Leaf1'),
            getAsset('colorMap1Leaf2'),
            getAsset('colorMap1Leaf3'),
          ],
        },
      },
    })
  }
}
