import { BufferGeometry, BufferAttribute, PlaneGeometry, Vector3 } from 'three'

export default class LeafGeometry extends BufferGeometry {
  constructor() {
    super()

    const count = 3

    const plane = new PlaneGeometry(1, 1, 64, 64)

    const vertexCount = plane.attributes.position.count
    const indicesCount = plane.index!.count

    const translates: any = [] as any
    const rotations = [] as any
    const frictions = [] as any
    const scales = [] as any

    const positions = [] as any
    const normals = [] as any
    const indices = [] as any
    const uids = [] as any
    const uvs = [] as any

    const transformsStore = [
      {
        translate: new Vector3(-0.6, -0.5, 0.66),
        rotate: new Vector3(0, 0, 0),
        scale: new Vector3(0.9, 0.9, 0.9),
      },
      {
        translate: new Vector3(0.8, -0.5, -0.25),
        rotate: new Vector3(0, 0, 0),
        scale: new Vector3(0.4, 0.4, 0.4),
      },
      {
        translate: new Vector3(0.6, 0.25, -0.5),
        rotate: new Vector3(0, 0, 0),
        scale: new Vector3(0.8, 0.8, 0.8),
      },
    ]

    let offset = 0

    for (let i = 0; i < count; i++) {
      const transform = transformsStore[i]

      for (let v = 0, u = 0; v < plane.attributes.position.array.length; v += 3, u += 2) {
        positions.push(
          plane.attributes.position.array[v + 0],
          plane.attributes.position.array[v + 1],
          plane.attributes.position.array[v + 2]
        )

        normals.push(
          plane.attributes.normal.array[v + 0],
          plane.attributes.normal.array[v + 1],
          plane.attributes.normal.array[v + 2]
        )

        uvs.push(plane.attributes.uv.array[u + 0], plane.attributes.uv.array[u + 1])

        // custom attributes
        translates.push(transform.translate.x, transform.translate.y, transform.translate.z)

        rotations.push(transform.rotate.x, transform.rotate.y, transform.rotate.z)

        scales.push(transform.scale.x, transform.scale.y, transform.scale.z)

        frictions.push(count - i)

        uids.push(i)
      }

      for (let j = 0; j < indicesCount; j++) {
        indices.push(plane.index!.getX(j) + offset)
      }

      offset += vertexCount
    }

    this.setAttribute('aTranslate', new BufferAttribute(new Float32Array(translates), 3))
    this.setAttribute('aFriction', new BufferAttribute(new Float32Array(frictions), 1))
    this.setAttribute('aRotate', new BufferAttribute(new Float32Array(rotations), 3))
    this.setAttribute('aScale', new BufferAttribute(new Float32Array(scales), 3))
    this.setAttribute('aUid', new BufferAttribute(new Float32Array(uids), 1))

    this.setAttribute('position', new BufferAttribute(new Float32Array(positions), 3))
    this.setAttribute('normal', new BufferAttribute(new Float32Array(normals), 3))
    this.setAttribute('uv', new BufferAttribute(new Float32Array(uvs), 2))

    this.setIndex(indices)
  }
}
