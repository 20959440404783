import { Mesh } from 'three'
import Geometry from './geometry'
import Material from './material'
import Shadow from './shadow'

export default class PackInstance extends Mesh {
  geometry: Geometry
  material: Material
  shadow: Shadow

  constructor({ theme, uniforms }: any) {
    super()

    this.geometry = new Geometry(theme)
    this.material = new Material(theme)

    this.shadow = new Shadow(theme.shadow)
    this.add(this.shadow)

    for (const key in uniforms) {
      this.material.uniforms[key].value = uniforms[key]
    }

    this.rotateY(Math.PI / 2)
    this.translateY(-0.75)
  }

  setProgres(progress: number) {
    this.material.uniforms.uProgress.value = progress
  }

  setPosition(position: number) {
    this.material.uniforms.uPosition.value = position
    this.shadow.setPosition(position)
  }

  setRotation(rotation: number) {
    this.material.uniforms.uRotation.value = rotation
    this.shadow.setRotation(rotation)
  }

  setScale(scale: number) {
    this.scale.setScalar(scale * 0.085)
  }

  dispose(deep?: boolean) {
    this.remove(this.shadow)
    this.material.dispose(deep)
    this.geometry.dispose()
    this.shadow.dispose()
  }
}
