import { getAsset } from '@/services'
import { BufferGeometry, BufferAttribute } from 'three'

export default class ProductGeometry extends BufferGeometry {
  constructor(theme: any) {
    super()

    const model = getAsset(theme.geometry) // .clone()

    this.setAttribute('position', new BufferAttribute(model.attributes.position.array, 3))
    this.setAttribute('normal', new BufferAttribute(model.attributes.normal.array, 3))
    this.setAttribute('uv', new BufferAttribute(model.attributes.uv.array, 2))
    //this.setAttribute('uv2', new BufferAttribute(model.attributes.uv.array, 2))

    /* this.computeBoundingBox()

    if (this.boundingBox) {
      this.translate(0, -10, 0)
      this.rotateY(Math.PI / 2)
      this.scale(0.085, 0.085, 0.085)
    } */
  }
}
