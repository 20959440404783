import { getAsset } from '@/services'
import { Glyph, TextLayout } from '../../types'
import { BufferGeometry, BufferAttribute } from 'three'
import createLayout from 'layout-bmfont-text'
import createIndices from 'quad-indices'

export default class CategoryGeometry extends BufferGeometry {
  layout!: TextLayout

  font = getAsset('font')

  constructor() {
    super()
  }

  update(text: string, side: number) {
    this.layout = createLayout({
      text: text.toUpperCase(),
      letterSpacing: 2,
      font: this.font,
    })

    const glyphs = this.layout.glyphs.filter(({ data: { width, height } }) => width * height > 0)

    const { scaleW, scaleH } = this.font.common

    const positions = this.positions(glyphs)
    const uvs = this.uvs(glyphs, scaleW, scaleH)
    const uvs2 = this.uvs2(glyphs)
    const sides = this.sides(glyphs, side)
    const times = this.times(glyphs)
    const heights = this.heights(glyphs)
    const indices = createIndices([], {
      count: glyphs.length,
      clockwise: true,
      type: 'uint16',
    })

    this.setAttribute('aPosition', new BufferAttribute(positions, 2))
    this.setAttribute('aUv', new BufferAttribute(uvs, 2))
    this.setAttribute('aUv2', new BufferAttribute(uvs2, 2))
    this.setAttribute('aSide', new BufferAttribute(sides, 1))
    this.setAttribute('aTime', new BufferAttribute(times, 2))
    this.setAttribute('aHeight', new BufferAttribute(heights, 1))
    this.setIndex(indices)
  }

  positions(glyphs: Glyph[]) {
    const positions = new Float32Array(glyphs.length * 4 * 2)

    let i = 0
    glyphs.forEach(({ data: bitmap, position }) => {
      // bottom left position
      const x = position[0] + bitmap.xoffset
      const y = position[1] + bitmap.yoffset

      // quad size
      const w = bitmap.width
      const h = bitmap.height

      // BL
      positions[i++] = x
      positions[i++] = y
      // TL
      positions[i++] = x
      positions[i++] = y + h
      // TR
      positions[i++] = x + w
      positions[i++] = y + h
      // BR
      positions[i++] = x + w
      positions[i++] = y
    })

    return positions
  }

  uvs(glyphs: Glyph[], scaleW: number, scaleH: number, flipY = true) {
    const uvs = new Float32Array(glyphs.length * 4 * 2)

    let i = 0
    glyphs.forEach(({ data: { width, height, x, y } }) => {
      const bw = x + width
      const bh = y + height

      // top left position
      const u0 = x / scaleW
      const u1 = bw / scaleW

      let v0 = bh / scaleH
      let v1 = y / scaleH
      if (flipY) {
        v0 = (scaleH - bh) / scaleH
        v1 = (scaleH - y) / scaleH
      }

      // BL
      uvs[i++] = u0
      uvs[i++] = v1
      // TL
      uvs[i++] = u0
      uvs[i++] = v0
      // TR
      uvs[i++] = u1
      uvs[i++] = v0
      // BR
      uvs[i++] = u1
      uvs[i++] = v1
    })

    return uvs
  }

  uvs2(glyphs: Glyph[]) {
    const uvs2 = new Float32Array(glyphs.length * 4 * 2)

    let i = 0
    glyphs.forEach(() => {
      const uy = 0.97

      // TL
      uvs2[i++] = 0
      uvs2[i++] = uy
      // BL
      uvs2[i++] = 0
      uvs2[i++] = 0
      // BR
      uvs2[i++] = 1
      uvs2[i++] = 0
      // TR
      uvs2[i++] = 1
      uvs2[i++] = uy
    })

    return uvs2
  }

  sides(glyphs: Glyph[], side: number) {
    const sides = new Float32Array(glyphs.length * 4 * 1)

    let i = 0
    glyphs.forEach(() => {
      // TL
      sides[i++] = side
      // BL
      sides[i++] = side
      // BR
      sides[i++] = side
      // TR
      sides[i++] = side
    })

    return sides
  }

  heights(glyphs: Glyph[]) {
    const heights = new Float32Array(glyphs.length * 4 * 1)

    let i = 0
    glyphs.forEach(({ data }) => {
      const height = data.height + 1

      // TL
      heights[i++] = height
      // BL
      heights[i++] = height
      // BR
      heights[i++] = height
      // TR
      heights[i++] = height
    })

    return heights
  }

  times(glyphs: Glyph[]) {
    const times = new Float32Array(glyphs.length * 4 * 2)

    let i = 0
    glyphs.forEach(() => {
      const value = Math.random() * 0.6
      const delay = Math.floor(value * 100) / 100

      // TL
      times[i++] = delay
      times[i++] = 1
      // BL
      times[i++] = delay
      times[i++] = 1
      // BR
      times[i++] = delay
      times[i++] = 1
      // TR
      times[i++] = delay
      times[i++] = 1
    })

    return times
  }
}
