import { PerspectiveCamera, Vector3 } from 'three'

export const useFullscreenPlane = (camera: PerspectiveCamera, position: Vector3) => {
  const { innerWidth, innerHeight } = window
  const aspect = innerWidth / innerHeight
  const distance = camera.position.z - position.z
  const vFov = (camera.fov * Math.PI) / 180
  const height = 2 * Math.tan(vFov / 2) * distance
  const width = height * aspect
  return { width, height }
}
