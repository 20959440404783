import { MediaQ } from '@/constants'
import { ShaderMaterial, Color, Vector2 } from 'three'
import fragmentShader from 'raw-loader!glslify-loader!./shaders/fragment.glsl'
import vertexShader from 'raw-loader!glslify-loader!./shaders/vertex.glsl'

export default class PatternMaterial extends ShaderMaterial {
  constructor() {
    super({
      fragmentShader,
      vertexShader,
      uniforms: {
        uTime: { value: 0 },
        uRepeat: { value: 0 },
        uProgress: { value: 0 },

        uPrevMap: { value: null },
        uNextMap: { value: null },

        uColor: { value: new Color(0xefefef) },
        uScreen: { value: new Vector2() },
      },
    })
  }

  setSize(width: number, height: number) {
    this.uniforms.uScreen.value.x = width
    this.uniforms.uScreen.value.y = height
    this.uniforms.uRepeat.value = MediaQ.MD > width ? 3 : 2.5
  }
}
