import { getAsset } from '@/services'
import { Color, ShaderMaterial } from 'three'
import fragmentShader from 'raw-loader!glslify-loader!./shaders/fragment.glsl'
import vertexShader from 'raw-loader!glslify-loader!./shaders/vertex.glsl'

export default class CategoryMaterial extends ShaderMaterial {
  constructor() {
    super({
      depthWrite: false,
      transparent: true,
      fragmentShader,
      vertexShader,
      /* extensions: {
        derivatives: true,
      }, */
      uniforms: {
        uProgress: { value: 1 },
        tDiffuse: { value: getAsset('msdf') },
        color: { value: new Color(0x000000) },
        opacity: { value: 0.1 },
      },
    })
  }
}
