import { Mesh, Color } from 'three'
import { MediaQ } from '@/constants'
import { Component, Vue, Prop, Watch } from 'vue-property-decorator'
import { useFullscreenPlane } from '@/webgl/hooks/math'
import Geometry from './geometry'
import Material from './material'

@Component
export default class Background extends Vue {
  @Prop() theme!: any
  @Prop() length!: number
  @Prop() uniforms!: any
  @Prop() progress!: number
  @Prop() direction!: number
  @Prop() transition!: number

  instance!: Mesh
  geometry!: Geometry
  material!: Material

  prevTheme!: any

  get forwards() {
    return this.direction > 0
  }

  @Watch('uniforms', { deep: true, immediate: true })
  async onUniformsUpdate(uniforms: any) {
    await this.$nextTick()
    for (const key in uniforms) this.material.uniforms[key].value = uniforms[key]
  }

  @Watch('progress', { immediate: true })
  async onProgressUpdate(progress: number) {
    await this.$nextTick()
    this.material.uniforms.uProgress.value = progress
  }

  @Watch('transition', { immediate: true })
  async onTransitionUpdate(transition: number) {
    await this.$nextTick()
    this.material.uniforms.uTransition.value = transition
  }

  @Watch('length')
  onSizeUpdate(length: number) {
    this.material.uniforms.uStrokeLength.value = length
  }

  @Watch('theme', { immediate: true })
  async onThemeUpdate(nextTheme: any) {
    await this.$nextTick()

    const prevColor = this.forwards ? 'uPrevColor' : 'uNextColor'
    const nextColor = this.forwards ? 'uNextColor' : 'uPrevColor'

    const nextColor0 = 'uNextColor0'
    const nextColor1 = 'uNextColor1'
    const nextColor2 = 'uNextColor2'

    const color = new Color(nextTheme.background)
    const color0 = color.clone().offsetHSL(0, 0, 0.05)
    const color1 = color.clone().offsetHSL(0, 0, 0.1)
    const color2 = color.clone().offsetHSL(0, 0, -0.05)

    if (this.prevTheme) this.material.uniforms[prevColor].value.set(this.prevTheme.background)

    this.material.uniforms[nextColor0].value.set(color0)
    this.material.uniforms[nextColor1].value.set(color1)
    this.material.uniforms[nextColor2].value.set(color2)
    this.material.uniforms[nextColor].value.set(color)

    this.prevTheme = { ...nextTheme }
  }

  resize(w: number, h: number) {
    const { camera } = this.gl
    const { position } = this.instance
    const { width, height } = useFullscreenPlane(camera.webgl, position)
    this.material.uniforms.uStrokeFrequency.value = MediaQ.MD > w ? 32 : 28
    this.material.uniforms.uResolution.value.set(w, h)
    this.geometry.setSize(width, height)
  }

  mounted() {
    this.geometry = new Geometry()
    this.material = new Material()
    this.instance = new Mesh(this.geometry, this.material)
    this.instance.position.set(0, 0, -3)
    this.gl.scene.webgl.add(this.instance)
  }

  beforeDestroy() {
    this.gl.scene.webgl.remove(this.instance)
    this.geometry.dispose()
    this.material.dispose()
  }

  render() {
    return null
  }
}
