import { Mesh } from 'three'
import { getAsset } from '@/services'
import { Component, Vue, Prop, Watch } from 'vue-property-decorator'
import Geometry from './geometry'
import Material from './material'

@Component
export default class ProductLeaf extends Vue {
  @Prop() theme!: any
  @Prop() scale!: number
  @Prop() opacity!: number
  @Prop() position!: number
  @Prop() progress!: number
  @Prop() direction!: number

  instance!: Mesh
  geometry!: Geometry
  material!: Material

  prevTheme!: any

  get forwards() {
    return this.direction > 0
  }

  @Watch('progress', { immediate: true })
  async onProgressUpdate(progress: number) {
    await this.$nextTick()
    this.material.uniforms.uProgress.value = progress
  }

  @Watch('position', { immediate: true })
  async onPositionUpdate(position: number) {
    await this.$nextTick()
    this.material.uniforms.uPosition.value = position
  }

  @Watch('opacity', { immediate: true })
  async onAlphaUpdate(opacity: number) {
    await this.$nextTick()
    this.material.uniforms.uAlpha.value = opacity
  }

  @Watch('scale', { immediate: true })
  async onScaleUpdate(scale: number) {
    await this.$nextTick()
    this.material.uniforms.uScale.value = scale
  }

  @Watch('theme', { immediate: true })
  async onThemeUpdate(nextTheme: any) {
    await this.$nextTick()

    const prevMap = this.forwards ? 'uPrevMaps' : 'uNextMaps'
    const nextMap = this.forwards ? 'uNextMaps' : 'uPrevMaps'

    if (this.prevTheme)
      this.material.uniforms[prevMap].value = this.prevTheme.leafs.map((leaf: string) =>
        getAsset(leaf)
      )

    this.material.uniforms[nextMap].value = nextTheme.leafs.map((leaf: string) => getAsset(leaf))

    this.prevTheme = { ...nextTheme }
  }

  tick(time: number) {
    this.material.uniforms.uTime.value = time
  }

  mounted() {
    this.geometry = new Geometry()
    this.material = new Material()
    this.instance = new Mesh(this.geometry, this.material)
    this.instance.position.set(0, 0, 0)
    this.gl.scene.webgl.add(this.instance)
  }

  beforeDestroy() {
    this.gl.scene.webgl.remove(this.instance)
    this.geometry.dispose()
    this.material.dispose()
  }

  render() {
    return null
  }
}
