import { Mesh } from 'three'
import { getAsset } from '@/services'
import { Component, Vue, Prop, Watch } from 'vue-property-decorator'
import { useFullscreenPlane } from '@/webgl/hooks/math'
import Geometry from './geometry'
import Material from './material'

@Component
export default class Patterns extends Vue {
  @Prop() theme!: any
  @Prop() progress!: number
  @Prop() direction!: number

  instance!: Mesh
  geometry!: Geometry
  material!: Material

  prevTheme!: any

  get forwards() {
    return this.direction > 0
  }

  @Watch('progress', { immediate: true })
  async onProgressUpdate(progress: number) {
    await this.$nextTick()
    this.material.uniforms.uProgress.value = progress
  }

  @Watch('theme', { immediate: true })
  async onThemeUpdate(nextTheme: any) {
    await this.$nextTick()

    const prevMap = this.forwards ? 'uPrevMap' : 'uNextMap'
    const nextMap = this.forwards ? 'uNextMap' : 'uPrevMap'

    if (this.prevTheme) this.material.uniforms[prevMap].value = getAsset(this.prevTheme.pattern)

    this.material.uniforms[nextMap].value = getAsset(nextTheme.pattern)

    this.prevTheme = { ...nextTheme }
  }

  tick(time: number) {
    this.material.uniforms.uTime.value = time
  }

  resize(w: number, h: number) {
    const { camera } = this.gl
    const { position } = this.instance
    const { width, height } = useFullscreenPlane(camera.webgl, position)
    this.geometry.setSize(width, height)
    this.material.setSize(w, h)
  }

  mounted() {
    this.geometry = new Geometry()
    this.material = new Material()
    this.instance = new Mesh(this.geometry, this.material)
    this.instance.position.set(0, 0, -8)
    this.gl.scene.webgl.add(this.instance)
  }

  beforeDestroy() {
    this.gl.scene.webgl.remove(this.instance)
    this.geometry.dispose()
    this.material.dispose()
  }

  render() {
    return null
  }
}
