import { PlaneGeometry } from 'three'

export default class PatternGeometry extends PlaneGeometry {
  vertices: number[] = []

  constructor() {
    super(2, 2)

    this.vertices = [...(this.attributes.position.array as any)]
  }

  setSize(width: number, height: number) {
    const position = this.attributes.position as any

    for (let i = 0, i3 = 0, l = position.count; i < l; ++i, i3 += 3) {
      const x = this.vertices[i3 + 0] * width
      const y = this.vertices[i3 + 1] * height
      const z = this.vertices[i3 + 2]
      position.array[i3 + 0] = x
      position.array[i3 + 1] = y
      position.array[i3 + 2] = z
    }

    this.attributes.position.needsUpdate = true
  }
}
