import { ShaderMaterial, Vector2, Color } from 'three'
import fragmentShader from 'raw-loader!glslify-loader!./shaders/fragment.glsl'
import vertexShader from 'raw-loader!glslify-loader!./shaders/vertex.glsl'

export default class BackgroundMaterial extends ShaderMaterial {
  constructor() {
    super({
      transparent: true,
      depthWrite: false,
      fragmentShader,
      vertexShader,
      uniforms: {
        uProgress: { value: 0 },
        uTransition: { value: 0 },

        uPrevColor: { value: new Color() },

        uNextColor: { value: new Color() },
        uNextColor0: { value: new Color() },
        uNextColor1: { value: new Color() },
        uNextColor2: { value: new Color() },

        uResolution: { value: new Vector2() },
        uStrokeFrequency: { value: 0 },
        uStrokeBleeding: { value: 0 },
        uStrokeRotation: { value: 0 },
        uStrokeStagger: { value: 0 },
        uStrokeLength: { value: 0.5 },
        uStrokeWidth: { value: 0 },
      },
    })
  }
}
