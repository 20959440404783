






import { gsap } from 'gsap'
import { Vector3, MathUtils } from 'three'
import { Component, Prop, Vue, Watch } from 'vue-property-decorator'
import { getAll } from '@/services'

@Component
export default class Canvas extends Vue {
  @Prop()
  mousemove!: boolean

  @Prop()
  viewport!: any

  up = new Vector3(0, 1, 0)

  center = new Vector3()

  moved = false

  @Watch('viewport', { immediate: true, deep: true })
  resize(
    { width, height }: any = {
      width: window.innerWidth,
      height: window.innerHeight,
    }
  ) {
    this.dispatch('resize', width, height)
  }

  tick(time: number) {
    if (!this.gl.enable) return
    this.dispatch('tick', time)
    this.animate()
    this.gl.render()
  }

  move() {
    const { client } = this.$pointer

    this.$pointer.mouse.x = (client.x / this.viewport.width) * 2 - 1
    this.$pointer.mouse.y = -(client.y / this.viewport.height) * 2 + 1

    this.moved = true
  }

  animate() {
    const { webgl: camera } = this.gl.camera
    const { mouse, speed } = this.$pointer

    camera.position.x = this.mousemove
      ? MathUtils.lerp(camera.position.x, mouse.x * Math.PI * 0.25, 0.1)
      : 0
    camera.position.y = this.mousemove
      ? MathUtils.lerp(camera.position.y, mouse.y * Math.PI * 0.15, 0.1)
      : 0

    this.up.x = this.mousemove
      ? MathUtils.lerp(this.up.x, speed * 0.0075 * ~~this.moved, 0.05)
      : 0

    camera.lookAt(this.center)
    camera.up = this.up
  }

  dispatch(method: string, ...args: any) {
    for (const child of this.$children) {
      const object = child as any
      object[method] ? object[method](...args) : null
    }
  }

  compile() {
    const { renderer, camera } = this.gl

    getAll((value) => {
      this.gl.renderer.webgl.initTexture(value)
    })

    for (const child of this.$children) {
      const object = child as any
      if (object.instance) {
        renderer.webgl.compile(object.instance, camera.webgl)
      }
    }
  }

  mounted() {
    this.compile()
    this.$pointer.$on('move', this.move)
    gsap.ticker.add(this.tick)
    this.resize()
  }

  beforeDestroy() {
    this.$pointer.$off('move', this.move)
    gsap.ticker.remove(this.tick)
  }
}
