import { Object3D } from 'three'
import { MediaQ } from '@/constants'
import { Component, Vue, Prop, Watch } from 'vue-property-decorator'
import Text from './Instance'

@Component
export default class CategoryText extends Vue {
  @Prop() label!: string
  @Prop() prevProgress!: number
  @Prop() nextProgress!: number

  prevInstance!: Text
  nextInstance!: Text

  container!: Object3D

  @Watch('prevProgress')
  onPrevProgressUpdate(progress: number) {
    if (this.prevInstance) this.prevInstance.progress(progress)
  }

  @Watch('nextProgress')
  onNextProgressUpdate(progress: number) {
    if (this.nextInstance) this.nextInstance.progress(progress)
  }

  @Watch('label', { immediate: true })
  async onLabelUpdate(nextLabel: string, prevLabel: string) {
    await this.$nextTick()

    if (prevLabel) this.prevInstance.update(prevLabel)
    this.nextInstance.update(nextLabel)
  }

  resize(width: number) {
    const scale = MediaQ.MD > width ? 0.04 : 0.06
    this.container.scale.setScalar(scale)
  }

  mounted() {
    this.container = new Object3D()
    this.container.position.set(0, 0, -3)
    this.container.scale.setScalar(1)

    this.prevInstance = new Text()
    this.nextInstance = new Text()
    this.container.add(this.prevInstance)
    this.container.add(this.nextInstance)

    this.gl.scene.webgl.add(this.container)
  }

  beforeDestroy() {
    this.gl.scene.webgl.remove(this.container)
    this.container.remove(this.prevInstance)
    this.container.remove(this.nextInstance)
    this.prevInstance.dispose()
    this.nextInstance.dispose()
  }

  render() {
    return null
  }
}
