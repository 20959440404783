import { getAsset } from '@/services'
import { MeshStandardMaterial, Color } from 'three'
import vertexShaderSetup from 'raw-loader!glslify-loader!./shaders/vertex-setup.glsl'
import vertexShaderExtend from 'raw-loader!glslify-loader!./shaders/vertex-extend.glsl'
import fragmentShaderSetup from 'raw-loader!glslify-loader!./shaders/fragment-setup.glsl'
import fragmentShaderExtend from 'raw-loader!glslify-loader!./shaders/fragment-extend.glsl'
import vertexShaderNormalExtend from 'raw-loader!glslify-loader!./shaders/vertex-normal-extend.glsl'
import fragmentShadeRoughnessExtend from 'raw-loader!glslify-loader!./shaders/fragment-roughness-extend.glsl'

export default class ProductMaterial extends MeshStandardMaterial {
  uniforms: any

  constructor(theme: any) {
    super({
      //roughnessMap: getAsset('roughness'),
      //aoMap: getAsset('occlusion'),
      normalMap: getAsset(theme.normal),
      transparent: true,
      alphaTest: 0.5,
      opacity: 1,
    })

    this.uniforms = {
      uProgress: { value: 0 },
      uPosition: { value: 0 },
      uRotation: { value: 0 },

      uPrevMap: { value: null },
      uNextMap: { value: null },

      uPrevOpacity: { value: 1 },
      uNextOpacity: { value: 1 },

      uPrevRoughness: { value: null },
      uNextRoughness: { value: null },

      uNextColor: { value: new Color() },
      uNextColorDark: { value: new Color() },
      uNextColorLight: { value: new Color() },

      uStrokeFrequency: { value: 0 },
      uStrokeBleeding: { value: 0 },
      uStrokeRotation: { value: 0 },
      uStrokeStagger: { value: 0 },
      uStrokeLength: { value: 1 },
      uStrokeWidth: { value: 0 },
    }

    this.onBeforeCompile = (shader: any) => {
      shader.defines = {
        ...shader.defines,
        USE_ROUGHNESSMAP: '',
      }

      shader.uniforms = {
        ...shader.uniforms,
        ...this.uniforms,
      }

      shader.vertexShader = shader.vertexShader
        .replace('#include <common>', vertexShaderSetup)
        .replace('#include <beginnormal_vertex>', vertexShaderNormalExtend)
        .replace('#include <begin_vertex>', vertexShaderExtend)

      shader.fragmentShader = shader.fragmentShader
        .replace('#include <common>', fragmentShaderSetup)
        .replace('#include <map_fragment>', fragmentShaderExtend)
        .replace('#include <roughnessmap_fragment>', fragmentShadeRoughnessExtend)
    }
  }

  dispose(deep?: boolean) {
    super.dispose()

    if (deep) {
      this.normalMap?.dispose()
      this.uniforms.uNextMap.value && this.uniforms.uNextMap.value.dispose()
      this.uniforms.uPrevMap.value && this.uniforms.uPrevMap.value.dispose()
      this.uniforms.uNextRoughness.value && this.uniforms.uNextRoughness.value.dispose()
      this.uniforms.uPrevRoughness.value && this.uniforms.uPrevRoughness.value.dispose()
    }
  }
}
